import React, { Component } from "react";
import Button from '../components/button';
import postscribe from 'postscribe';

// Styles
import * as customStyles from '../styles/scss/components/reviewForm.module.scss';

export default class ReviewForm extends Component {

    constructor(props, context) {
        super(props, context)
        this.writeReview = this.writeReview.bind(this)
    }

    shouldComponentUpdate() {
        return false
    }

    writeReview = (e) => {
        const { productId, brand } = this.props
        let bvUrl = ""
        switch (brand) {
            case "bike":
                bvUrl = `<script language="javascript" src="//display.ugc.bazaarvoice.com/static/wd40bike/main_site/en_US/bvapi.js"></script>`
                break;
            case "specialist":
                bvUrl = `<script language="javascript" src="//display.ugc.bazaarvoice.com/static/WD40Specialist/main_site/en_US/bvapi.js"></script>`
                break;
            case "wd40":
            default:
                bvUrl = `<script language="javascript" src="//display.ugc.bazaarvoice.com/static/WD40/main_site/en_US/bvapi.js"></script>`
        }
        postscribe('#jscontainer', bvUrl, {
            done: function () {
                this.bv = window.$BV;
                this.bv.ui('rr', 'submit_review', { productId: productId });
            }
        })
        e.preventDefault()
    }

    render() {
        const { type, styles, ctaText = 'Write a review' } = this.props

        if (type === "button") {
            return (
                <div className={styles}>
                    <Button
                        className={customStyles.WriteAReviewCTAButton}
                        text={ctaText}
                        blue
                        big
                        onClick={this.writeReview}
                    />
                    <div id="jscontainer"></div>
                </div>
            )
        } else {
            return (
                <div role="button" tabIndex={0} onKeyDown={this.writeReview} onClick={this.writeReview}>
                    {ctaText}
                    <div id="jscontainer"></div>
                </div>
            )
        }
    }
}
