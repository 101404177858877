import React from 'react';
import classnames from 'classnames';
import { Tabs, Tab } from 'react-bootstrap';

// Styles
import * as styles from '../styles/scss/components/productTabs.module.scss';

const ProductTabs = ({
  id,
  className = '',
  tabs = [],
  defaultActiveKey = undefined,
  transition = false,
}) => {
  
  if (tabs.length === 0) {
    return null;
  }

  return (
    <div
      id={id}
      className={classnames(className, styles.ProductTabsMainContainer)}
    >
      <div
        className={styles.ProductTabsContent}
      >
      <Tabs
        defaultActiveKey={defaultActiveKey}
        transition={transition}
        className="custom-products-tabs"
      >
        {
          tabs.map(tab => (
            <Tab
              key={tab.key}
              eventKey={tab.key}
              title={tab.title}
            >
              {tab.content}
            </Tab>
          ))
        }       
        </Tabs>
      </div>
    </div>
  );
}

export default React.memo(ProductTabs);
