import React from 'react';
import classnames from 'classnames';

// Components
import Title from '../components/title';
import ReviewForm from '../components/reviewForm';
import Rating from '../components/rating';
import NewRatingSnapshot from '../components/newRatingSnapshot';
import NewReview from '../components/newReview';

// JSON
import bvReviewDataJSON from '../data/bvreviews.json';

// Styles
import * as styles from '../styles/scss/components/productReviews.module.scss';

export const getReviewStats = function getReviewStats(product) {
  let reviewStats = bvReviewDataJSON.filter(e => e.id === product.bazaarVoiceId);
  return reviewStats[0];
}

// NOTE: Keep it for future use
// const getAuthor = function getAuthor(authorId, pageContextReviews) {
//   if (pageContextReviews && pageContextReviews.authors) {
//     const targetAuthor = pageContextReviews.authors.find(e => e.id === authorId);
//     if (targetAuthor) {
//       return targetAuthor.name;
//     }
//   }
//   return null;
// }

const limit = 3;

const ProductReviews = ({
  className = '',
  product,
  pageContextReviews,
  pageContentBrand,
}) => {


  // States
  const [reviewsLimit, setReviewLimit] = React.useState(limit);

  // Memos
  const {
    hasMoreReviews,
    reviews,
    transformedProduct,
    ratingSnapshotValues,
  } = React.useMemo(() => {
    const out = {
      reviews: [],
      transformedProduct: { ...product },
      ratingSnapshotValues: [],
      hasMoreReviews: false,
    };

    if (pageContextReviews) {
      const allReviews = (pageContextReviews.Results || []);

      const allReviewsBySubmissionTime = allReviews.sort(
        (objA, objB) => Number(objB.SubmissionTime) - Number(objA.SubmissionTime),
      );

      out.reviews = allReviewsBySubmissionTime.slice(0, reviewsLimit);
      out.hasMoreReviews = out.reviews.length < allReviews.length;
    }

    if (product) {
      if (product.bazaarVoiceId) {
        const reviewStats = getReviewStats(product);
        out.transformedProduct = {
          ...out.transformedProduct,
          reviewStats,
        };
      }

      out.transformedProduct.relatedProducts.forEach((p, idx) => {
        if (p.bazaarVoiceId) {
          const reviewStats = getReviewStats(p);
          const newRelatedProducts = [...out.transformedProduct.relatedProducts];
          newRelatedProducts[idx] = { ...newRelatedProducts[idx], reviewStats }
          out.transformedProduct = {
            ...out.transformedProduct,
            relatedProducts: newRelatedProducts,
          }
        }
      });

      // build rating snapshot array
      if (out.transformedProduct.reviewStats) {
        for (let i = 5; i > 0; i--) {
          const reviewStats = out.transformedProduct.reviewStats.ratingDistribution.filter(e => e.RatingValue === i);
          if (reviewStats.length > 0) {
            out.ratingSnapshotValues.push(reviewStats[0].Count)
          }
          else {
            out.ratingSnapshotValues.push(0);
          }
        }
      }
    }

    return out;
  }, [reviewsLimit, pageContextReviews, product]);

  // Handlers
  const handleLoadMoreReviews = React.useCallback((e) => {
    if (e.key !== "Tab" && e.key !== "Shift") {
      setReviewLimit(s => s + limit);
    }
  }, []);

  // Renders
  const renderAverageCustomerRatings = (
    <div
      className={styles.AverageCustomerRatingsContainer}
    >
      <Rating
        value={transformedProduct.reviewStats || 0}
        bold
        noPadding
        newStyle
      />
    </div>
  );

  const renderCustomerReviews = (
    <div
      className={styles.CustomerReviewsContainer}
    >
      {/* Customer Reviews Top */}
      <div
        className={styles.CustomerReviewsContainerTop}
      >
        <div
          className={styles.CustomerReviewsContainerTopLeft}
        >
          {/* Title */}
          <Title
            className={styles.CustomerReviewsContainerTopTitle}
            value='CUSTOMER REVIEWS'
          />

          {/* Average Ratings */}
          {renderAverageCustomerRatings}
        </div>

        <div
          className={styles.CustomerReviewsContainerTopRight}
        >
          {/* Review Form */}
          {
            transformedProduct.bazaarVoiceId && (
              <ReviewForm
                type="button"
                styles={styles.CustomerReviewsContainerTopCTAButton}
                productId={transformedProduct.bazaarVoiceId}
                brand={pageContentBrand}
              />
            )
          }
        </div>
      </div>

      {/* Customer Reviews Bottom */}
      <div
        className={styles.CustomerReviewsContainerBottom}
      >
        <NewRatingSnapshot
          values={ratingSnapshotValues}
        // NOTE: You can "filter" specific review points using this (not used now)
        // onSelectRow={handleOnSelectRow}
        />
      </div>
    </div>
  );

  const renderCustomerReviewsList = (
    <>
      {
        reviews && reviews.length > 0 && (
          <>
            <div
              className={styles.CustomerReviewsList}
            >
              {
                reviews.map(review => (
                  <NewReview
                    key={review.Id}
                    className={classnames(styles.ReviewStyle, 'new-review')}
                    review={review}
                  />
                ))
              }
            </div>

            {/* Load More */}
            {
              hasMoreReviews && (
                <div
                  tabIndex={0}
                  role='button'
                  className={styles.LoadMoreButton}
                  onClick={handleLoadMoreReviews}
                  onKeyDown={handleLoadMoreReviews}
                >
                  {'LOAD MORE REVIEWS >>'}
                </div>
              )
            }
          </>
        )
      }
    </>
  );

  return (
    <div
      className={classnames(className, styles.ProductReviewsMainContainer)}
    >
      <div
        className={styles.ProductReviewsContent}
      >
        {/* Customer Reviews (General Information) */}
        {renderCustomerReviews}

        {/* Customer Reviews (Reviews) */}
        {renderCustomerReviewsList}
      </div>
    </div>
  );
}

export default React.memo(ProductReviews);
