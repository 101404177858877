import React, { useState } from 'react';
import classnames from 'classnames';

// Components
import Title from './title';
import VideoImageBlock from './videoImageBlock';
import GalleryModal from './galleryModal';

// Services
import { supSub } from '../services/util';

// Styles
import * as styles from '../styles/scss/components/productsInUse.module.scss';

const ProductsInUse = ({
  className = '',
  product,
}) => {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  // Memos
  const {
    items,
  } = React.useMemo(() => {
    const out = {
      items: [],
    };

    if (product && product.possibleUses && product.possibleUses.length > 0) {
      out.items = product.possibleUses.filter(item => !!((item.media && item.media.url) || (item.video && item.video.url)));
    }

    return out;
  }, [product]);

  if (items.length === 0) {
    return null;
  }

  const imagesInUse = items.filter(item => { return item.media });
  const videosInUse = items.filter(item => { return item.video });

  const setImageToModal = (item, index) => {
    setCurrentIndex(index);
    setIsModalOpen(!isModalOpen);
  }

  return (
    <div
      id="products-in-use-element"
      className={classnames(className, styles.ProductsInUseMainContainer)}
    >
      {/* Title */}
      <Title
        className={styles.ProductInUseTitle}
        value={supSub(`Uses for ${product.displayName}`)}
      />

      {/* List */}
      <div
        className={styles.ProductInUseArticlesList}
      >
        {
          items.map((item, index) => (
            <div
              key={item.id}
              className={styles.ProductInUseItem}
            >
              {/* Image */}
              {
                item.media && (
                  <div 
                    role='button'
                    tabIndex={0}
                    onKeyDown={(e) => {
                      if (e.key !== "Tab" && e.key !== "Shift") {
                        setImageToModal(item, index)
                      }
                    }}
                    onClick={() => setImageToModal(item, index)}
                  >
                  <img
                    className={styles.ProductInUseItemImage}
                    src={item.media.url}
                    draggable={false}
                    alt="product in use"
                    
                    
                  /></div>
                )
              }

              {/* Video */}
              {
                item.video && (
                  <VideoImageBlock
                    containerClass={styles.ProductInUseItemVideo}
                    hideBody
                    youtubeVideoId={item.video.providerUid}
                    image={item.video.thumbnailUrl}
                  />
                )
              }
            </div>
          ))
        }
        {
          isModalOpen &&
          <GalleryModal
            images={imagesInUse}
            videos={videosInUse}
            isModalOpen={isModalOpen}
            setCurrentIndex={setCurrentIndex}
            currentIndex={currentIndex}
            setIsModalOpen={setIsModalOpen} />
        }
      </div>
    </div>
  );
}

export default React.memo(ProductsInUse);
