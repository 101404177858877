import React from 'react';
import cn from 'classnames';

// Components
import Title from '../components/title';
import ImageBlock from '../components/imageBlock';

// Styles
import styles from '../styles/scss/components/productIcons.module.scss';

const  ProductIcons = ({
    title,
    productIcons,
}) => {

    return (
        <div
            className={styles.productIconsContainer}
        >
            <Title
                value={title}
                className={styles.iconTitle}
            />

            <div
                className={cn(styles.productIcons, (productIcons && productIcons.length < 3) ? styles.lessThan3Container : undefined)}
            >
                {
                    productIcons && productIcons.map((item, key) => (
                        <div
                            key={key}
                            className={cn(styles.productIndividualIcon, productIcons.length < 3 ? styles.lessThan3 : undefined)}
                        >
                            <ImageBlock
                                image={item.iconImage.url}
                                containerClass={styles.imageContainer}
                                imageClass={styles.productImage}
                            />

                            <Title
                                className={styles.productIconTitle}
                                value={item.iconName}
                            />
                        </div>
                    ))
                }
            </div>
        </div>
    );
}

export default React.memo(ProductIcons);

