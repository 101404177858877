import React from "react"

// Components
import Card from "./card"
import Title from "./title"

// Styles
import styles from "../styles/scss/components/relatedProductBlogPost.module.scss"

const relatedProductBlogPost = ({ blogs }) => (
    <div className={styles.MainContainer}>
        <div className={styles.content}>
            <Title className={styles.title} value="Related blog posts" />
            <div className={styles.cards}>
                {
                    blogs[0] && (
                        <div className={styles.card}>
                            <Card
                                type="relatedProductBlog"
                                title={blogs[0].title}
                                description={blogs[0].teaser}
                                date={blogs[0].dateOfArticle}
                                image={blogs[0].heroImage.url}
                                link={blogs[0].slug} />
                        </div>
                    )
                }
                {
                    blogs[1] && (
                        <div className={styles.card}>
                            <Card
                                type="relatedProductBlog"
                                title={blogs[1].title}
                                description={blogs[0].teaser}
                                date={blogs[0].dateOfArticle}
                                image={blogs[1].heroImage.url}
                                link={blogs[1].slug} />
                        </div>
                    ) 
                }
            </div>
        </div>        
    </div>
)

export default relatedProductBlogPost;
