import React from 'react';
import classnames from 'classnames';
import Title from './title';
import Card from './card';

// Styles
import * as styles from '../styles/scss/components/relatedProductsNew.module.scss';

const RelatedProductsNew = ({
  className = '',
  products = [],
}) => {
  const [rerender, setRerenderer] = React.useState(1);

  // Effects
  React.useEffect(() => {
    const updateRerender = function () {
      setRerenderer(s => s + 1)
    };
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateRerender);
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', updateRerender);
      }
    }
  }, []);
  // Memos
  const groupsOfTwoProducts = React.useMemo(() => {
    let out = [];
    if (products && products.length > 0) {
      const perChunk = 2;
      out = products.reduce((pV, cV, idx) => {
        const ch = Math.floor(idx / perChunk);
        pV[ch] = [].concat((pV[ch] || []), cV);
        return pV;
      }, [])
    }
    return out;
  }, [products]);

  // Renders

  // Custom Arrows for carousels

  const renderCardProduct = (product) => {
    return (
      <Card
        type="product"
        title={product.displayName}
        image={product.listImage ? product.listImage.url : ''}
        product={product}
        slug={product.slug}
      >
        {
          product.teaser && (
            <p>
              {product.teaser}
            </p>
          )
        }
      </Card>
    );
  }

  const renderProductLists = (
    <div className={styles.SliderContainer}>      
      {
        groupsOfTwoProducts.slice(0, 1).map((group, idx) => (
          <div
            key={idx}
            className={styles.slideContent}
          >
            {
              group[0] && (
                <div
                  className={styles.slideContentLeft}
                >
                  {renderCardProduct(group[0])}
                </div>
              )
            }

            {
              group[1] && (

                <div
                  className={styles.slideContentRight}
                >
                  {renderCardProduct(group[1])}
                </div>
              )
            }
          </div>
        ))
      }
    </div>
  );

  if (products.length === 0) {
    return null;
  }

  return (
    <div
      key={rerender}
      className={classnames(className, styles.RelatedProductsMainContainer)}
    >
      <div
        className={styles.BG}
      />

      <div
        className={styles.RelatedProductsMainContent}
      >
        <Title
          className={styles.MainTitle}
          value='RELATED PRODUCTS'
        />

        {
          products.length > 0 && (
            <>
              {renderProductLists}
            </>
          )
        }
      </div>

    </div>
  );
}

export default React.memo(RelatedProductsNew );
