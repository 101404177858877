import React, { Component } from 'react';
import ProductInformation from '../components/productInformation';
import styles from '../styles/scss/templates/productDetail.module.scss';
import NewLayout from "../layout/newLayout"
import SEO from "../components/seo";
import ProductIcons from '../components/productsIcons';
import CtaBanner from "../components/ctaBanner";
import RelatedProductsNew from "../components/relatedProductsNew";
import ProductTabs from '../components/productTabs';
import ProductsInUse from '../components/productsInUse';
import ProductsTipsAndTricks from '../components/productsTipsAndTricks';
import ProductReviews, { getReviewStats } from '../components/productReviews';
import EventAnchor from '../components/eventAnchor';
import ProductRelatedBlogPostsNew from '../components/relatedProductBlogPost';
import { supSub } from '../services/util';

export default class ProductDetail extends Component {
    eraserTabs = 0;

    constructor(props, context) {
        super(props, context);
        this.state = {
            productWithReviewStats: this.props.pageContext.product,
            defaultActiveKey: 'products-in-use',
            bodyIsOpen: false,
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        const { pageContext } = this.props
        const product = pageContext.product;        
        let productWithReviewStats = product;

        if (product.bazaarVoiceId) {
            const reviewStats = getReviewStats(product);
            productWithReviewStats = {
                ...productWithReviewStats,
                reviewStats,
            };
        }

        productWithReviewStats.relatedProducts.forEach((p, idx) => {
            if (p.bazaarVoiceId) {
                const reviewStats = getReviewStats(p);
                const newRelatedProducts = [...productWithReviewStats.relatedProducts];
                newRelatedProducts[idx] = { ...newRelatedProducts[idx], reviewStats }
                productWithReviewStats = {
                    ...productWithReviewStats,
                    relatedProducts: newRelatedProducts,
                }
            }
        });

        this.setState({
            productWithReviewStats,
        });

        const pricespiderscript = document.createElement("script")
        pricespiderscript.src = "//cdn.pricespider.com/1/lib/ps-widget.js"
        pricespiderscript.async = true
        document.body.appendChild(pricespiderscript)
    }

    goToProductInUse = () => {
        this.eraserTabs += 1;
        this.setState({
            defaultActiveKey: 'products-in-use',
        }, () => {
            if (typeof window !== 'undefined') {
                const targetElement = window.document.getElementById('product-tabs-id');
                if (targetElement) {
                    targetElement.scrollIntoView({ behavior: 'smooth' });
                }
            }
        });
    }

    goToCustomerReviews = () => {
        this.eraserTabs += 1;
        this.setState({
            defaultActiveKey: 'reviews',
        }, () => {
            if (typeof window !== 'undefined') {
                const targetElement = window.document.getElementById('product-tabs-id');
                if (targetElement) {
                    targetElement.scrollIntoView({ behavior: 'smooth' });
                }
            }
        });
    }

    render() {
        const isNewLayout = true;
        const { pageContext } = this.props;

        const product = this.state.productWithReviewStats;
        const pageContextReviews = pageContext.reviews;
        const pageContextBrand = pageContext.brand;

        const page = {
            title: product.displayName + " " + product.subtitle,
            seo: product.seo,
            tags: product.tags
        }

        const bodyContent = pageContext.product.content[0];
        const dotsContent = pageContext.product.content[1];
        const disclaimerContent = pageContext.product.content[2];
        const productIcons = pageContext.product.productIcons;

        var productTabs = []

        if (pageContext.product && pageContext.product.possibleUses && pageContext.product.possibleUses.length > 0) {
            var possibleUses = pageContext.product.possibleUses;
        }

        if (pageContext.product && pageContext.product.productTipsAndTricks && pageContext.product.productTipsAndTricks.length > 0) {
            var productTipsAndTricks = pageContext.product.productTipsAndTricks;
        }

        if (pageContext.product && pageContext.product.relatedBlogs && pageContext.product.relatedBlogs.length > 0) {
            var blogs = pageContext.product.relatedBlogs;
        }

        if (possibleUses || productTipsAndTricks || blogs) {
            productTabs.push({
                key: 'products-in-use',
                title: 'PRODUCTS IN-USE',
                content: (
                    <div
                        className={styles.ProductsInUseContainer}
                    >
                        {possibleUses && <ProductsInUse
                            product={product}
                        />}

                        {productTipsAndTricks && <ProductsTipsAndTricks
                            product={product}
                        />}                        
                    </div>
                ),
            })
        }

        productTabs.push(
            {
                key: 'related-products',
                title: 'RELATED PRODUCTS',
                content: (
                    <RelatedProductsNew
                        products={product.relatedProducts}
                    />
                ),
            })

        productTabs.push(
            {
                key: 'reviews',
                title: 'CUSTOMER REVIEWS',
                content: (
                    <ProductReviews
                        product={product}
                        pageContextReviews={pageContextReviews}
                        pageContextBrand={pageContextBrand}
                    />
                ),
            })
        const bodyCharsCounter = bodyContent ? bodyContent.body.length : 0;
        const charsLimit = 836;
        return (
            <NewLayout>
                <SEO page={page} />
                <div className='d-flex flex-column flex-fill'>
                    <div className={isNewLayout ? styles.descriptionDetailNewLayout : styles.descriptionDetail}>
                        <ProductInformation
                            product={product}
                            brand={pageContext.brand}
                            reviewStats={product.reviewStats || false}
                            goToProductInUse={this.goToProductInUse}
                            goToCustomerReviews={this.goToCustomerReviews}
                            productInUse={possibleUses}
                            seeProductInUse={() => (
                                <>
                                {possibleUses && <button className={styles.seeProductInUse} href="#" onClick={() => this.goToProductInUse()}>{`See Product In Use >>`}</button>}
                                
                                </>
                                    
                            )}
                        >
                            {bodyContent &&
                                <div className={styles.leftColumnData}>
                                    {
                                        bodyCharsCounter >= charsLimit ?
                                        <>
                                            <div className={this.state.bodyIsOpen ? styles.bodyShowLess : styles.bodyShowMore }>
                                                <div dangerouslySetInnerHTML={{ __html: supSub(bodyContent.body) }} />
                                                {disclaimerContent && <div className={styles.disclaimer} dangerouslySetInnerHTML={{ __html: supSub(disclaimerContent.body) }} />}
                                            </div>
                                            
                                            <EventAnchor text={this.state.bodyIsOpen ? "READ LESS >>" : "READ MORE >>"} noMargin event={() => {
                                                    if(this.state.bodyIsOpen === true) {
                                                        window.scrollTo({ top: 0, behavior: 'smooth' });
                                                    }
                                                    this.setState({ bodyIsOpen: !this.state.bodyIsOpen})
                                                }} />
                                        </>
                                        
                                        :
                                        <>
                                            <div dangerouslySetInnerHTML={{ __html: supSub(bodyContent.body) }} />
                                            {disclaimerContent && <div className={styles.disclaimer} dangerouslySetInnerHTML={{ __html: supSub(disclaimerContent.body) }} />}
                                        </>
                                        

                                    }
                                    
                                    
                                </div>
                            }
                            <div className={styles.rightColumnData}>
                                <div className={styles.topContentAnchors}>
                                    {dotsContent && <div dangerouslySetInnerHTML={{ __html: supSub(dotsContent.body) }} />}
                                    <ul>
                                        {product.safetyData && <li><a href={product.safetyData} target="_new">SDS {'>>'}</a></li>}
                                        {product.technicalData && <li><a href={product.technicalData.url} target="_new"> Technical Data Sheet {'>>'}</a></li>}
                                        {product.whatsInIt && <li><a href={product.whatsInIt.url} target="_new">What's In it {'>>'}</a></li>}
                                    </ul>
                                </div>
                                {productIcons && <ProductIcons title="Works Great On:" productIcons={productIcons} />}
                            </div>
                        </ProductInformation>
                    </div>

                    {/* Related Blog Posts */}

                    {blogs && <ProductRelatedBlogPostsNew blogs={blogs} />}

                    <CtaBanner 
                        text="WANT TO GET MORE TIPS AND TRICKS?" 
                        buttonText="SUBSCRIBE TO THE NEWSLETTER" 
                        buttonUrl="/signup/" 
                    />

                    {/* Product Tabs */}
                    <ProductTabs
                        id="product-tabs-id"
                        key={this.eraserTabs}
                        defaultActiveKey={this.state.defaultActiveKey}
                        tabs={productTabs}
                    />
                </div>                
            </NewLayout>
        );
    }
}
