import React from 'react';
import classnames from 'classnames';

// Styles
import styles from '../styles/scss/components/newRatingSnapshot.module.scss';

const NewRatingSnapshot = ({
    className = '',
    values,
    onSelectRow,
  }) => {
    // Handlers
    const handleOnSelectRow = React.useCallback((row) => {
        if (onSelectRow) {
            onSelectRow(row);
        }
    }, [onSelectRow]);

    // Memos
    const items = React.useMemo(() => {
        let out = Array(5).fill(0);
        if (values) {
            const totalReviewsAmount = values.reduce((pV, cV) => {
                return pV + cV;
            }, 0);
            if (totalReviewsAmount > 0) {
                out = values.map((value) => {
                    return Math.round((value / totalReviewsAmount) * 100);
                });
            }
        }
        return out;
    }, [values]);

    return (
        <div
            className={classnames(className, styles.NewRatingSnapshotMainContainer)}
        >
            {
                items.map((value, idx) => {
                    const stars = (5 - idx);
                    const starsArray = new Array(stars).fill(null);

                    return (
                        <div
                            key={idx}
                            tabIndex={0}
                            role="button"
                            onKeyDown={(e)=>{
                                if (e.key !== "Tab" && e.key !== "Shift") {
                                    handleOnSelectRow(stars)
                                }
                            }}
                            className={styles.NewRatingSnapshotRow}
                            onClick={() => {
                                handleOnSelectRow(stars)}}
                        >
                            
                            <div
                                className={styles.NewRatingSnapshotColLeft}
                            >
                                {
                                    starsArray.map((_, idx2) => (
                                        <i
                                            key={idx2}
                                            className='fa fa-star'
                                        />
                                    ))
                                }
                            </div>
                            <div
                                className={styles.NewRatingSnapshotColRight}
                            >
                                <div className={styles.Bar}>
                                    <div
                                        style={{ width: `${value}%` }}
                                    />
                                </div>

                                <div
                                    className={styles.BarValue}
                                >
                                    {`${value}%`}
                                </div>
                            </div>
                        </div>
                    );
                })
            }
        </div>
    );
  };

export default React.memo(NewRatingSnapshot);
