import React from 'react';
import moment from 'moment';
import classnames from 'classnames';

// Components
import Rating from './rating';

// Styles
import styles from '../styles/scss/components/newReview.module.scss';

const multiLineOverflows = function multiLineOverflows(nativeElement) {
    return nativeElement.scrollHeight > nativeElement.clientHeight;
}

const NewReview = ({
    className,
    review,
}) => {
    // Refs
    const wd40ResponseRef = React.useRef(null);

    // States
    const [showAllWD40Response, setShowAllWD40Response] = React.useState(false)

    // Effects
    React.useEffect(() => {
        if (typeof window !== 'undefined' && wd40ResponseRef.current) {
            const hasOverflow = multiLineOverflows(wd40ResponseRef.current);
            setShowAllWD40Response(!hasOverflow);
        }
    }, []);

    // Handlers
    const handleLoadAllWD40Response = React.useCallback((e) => {
        if (e.key !== "Tab" && e.key !== "Shift") {
            setShowAllWD40Response(true);
        }
        
    }, []);

    // Memos
    const {
        submissionTime,
        rating,
        title,
        reviewText,
        photos,
        clientResponses,
    } = React.useMemo(() => {
        const out = {
            submissionTime: undefined,
            rating: undefined,
            title: undefined,
            reviewText: undefined,
            photos: undefined,
            clientResponses: undefined,
        };
        
        if (review) {
            // SubmissionTime
            if (review.SubmissionTime) {
                out.submissionTime = moment(review.SubmissionTime).format('DD/MM/YYYY');
            }

            // Rating
            if (review.Rating) {
                out.rating = review.Rating;
            }

            // Title
            if (review.Title) {
                out.title = review.Title;
            }

            // ReviewText
            if (review.ReviewText) {
                out.reviewText = review.ReviewText;
            }

            // Photos
            if (review.Photos && review.Photos.length > 0) {
                out.photos = review.Photos.map(photo => {
                    let url = '';
                    if (photo.Sizes && photo.Sizes.normal) {
                        url = photo.Sizes.normal.Url;
                    }
                    return {
                        id: photo.Id,
                        caption: photo.Caption,
                        url,
                    };
                });
            }

            // ClientResponses
            if (review.ClientResponses && review.ClientResponses.length > 0) {
                out.clientResponses = review.ClientResponses;
            }
        }

        return out;
    }, [review]);

    return (
        <div
            className={classnames(className, styles.NewReviewMainContainer)}
        >
            {/* Top Container */}
            <div
                className={styles.TopContainer}
            >
                {/* Rating */}
                {
                    rating && (
                        <Rating
                            value={rating}
                            spacing
                            hideText
                            noPadding
                            newStyle
                        />
                    )
                }

                {/* Submission Date */}
                {
                    submissionTime && (
                        <div
                            className={styles.SubmissionTime}
                        >
                            {submissionTime}
                        </div>
                    )
                }
            </div>

            {/* Bottom Container */}
            <div
                className={styles.BottomContainer}
            >
                {/* Title */}
                {
                    title && (
                        <div
                            className={styles.ReviewTitle}
                        >
                            {title}
                        </div>
                    )
                }

                {/* Bottom Container Row */}
                <div
                    className={styles.BottomContainerRow}
                >
                    <div
                        className={styles.ReviewLeftContainer}
                    >
                        {/* Review Text */}
                        {
                            reviewText && (
                                <div
                                    className={styles.ReviewText}
                                >
                                    {reviewText}
                                </div>
                            )
                        }

                        {/* Client Response */}
                        {
                            clientResponses && clientResponses.length > 0 && (
                                <div
                                    className={styles.ReviewClientResponseContainer}
                                >
                                    <div
                                        className={styles.ReviewClientResponseContainerLeft}
                                    >
                                        <img
                                            className={styles.WD40Logo}
                                            src="/img/icons/WD40_logo_alternative.png"
                                            alt="WD-40 Logo"
                                        />
                                    </div>

                                    <div
                                        className={styles.ReviewClientResponseContainerRight}
                                    >
                                        <div
                                            className={styles.ReviewClientResponseContainerRightTitle}
                                        >
                                            WD-40<span>®</span> Brand
                                        </div>

                                        <div
                                            ref={wd40ResponseRef}
                                            className={classnames(
                                                styles.ReviewClientResponseContainerRightDescription,
                                                {
                                                    [styles.wLineClamp]: !showAllWD40Response,
                                                },
                                            )}
                                        >
                                            {clientResponses[0].Response}
                                        </div>
                                        {
                                            !showAllWD40Response && (
                                                <div
                                                    tabIndex={0}
                                                    role="button"
                                                    className={styles.ReviewClientResponseContainerRightLoadMore}
                                                    onClick={handleLoadAllWD40Response}
                                                    onKeyDown={handleLoadAllWD40Response}
                                                >
                                                    {'Read More >>'}
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            )
                        }

                    </div>

                    {/* Photos */}
                    {
                        photos && photos.length > 0 && (
                            <div
                                className={styles.ReviewPhotosContainer}
                            >
                                {
                                    photos.map(photo => (
                                        <div
                                            key={photo.id}
                                            className={styles.ReviewPhotoContainer}
                                        >
                                            <img
                                                className={styles.ReviewPhoto}
                                                src={photo.url}
                                                alt={photo.caption}
                                            />
                                        </div>
                                    ))
                                }
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    );
}

export default React.memo(NewReview);
