import React from 'react';
import classnames from 'classnames';

// Components
import Title from './title';

// Services
import { supSub } from '../services/util'

// Styles
import * as styles from '../styles/scss/components/productsTipsAndTricks.module.scss';

const ProductsTipsAndTricts = ({
  className = '',
  product,
}) => {
  // Memos
  const {
    tipsAndTricks,
  } = React.useMemo(() => {
    const out = {
      tipsAndTricks: []
    };

    if (product.productTipsAndTricks && product.productTipsAndTricks.length > 0) {
      out.tipsAndTricks = product.productTipsAndTricks.map(item => {
        return {
          id: item.id,
          html: supSub(item.content),
        }
      });
    }

    return out;
  }, [product]);

  if (tipsAndTricks.length === 0) {
    return null;
  }

  return (
    <div
      className={classnames(className, styles.ProductsTipsAndTrictsMainContainer)}
    >
      {/* Title */}
      <Title
        className={styles.ProductTipsAndTricksTitle}
        value={`TIPS & TRICKS`}
      />

      {/* Content */}
      <ul
        className={styles.ProductTipsAndTricksList}
      >
        {
          tipsAndTricks.map((item, idx) => (
            <li
              key={idx}
              className={styles.ProductTipsAndTricksItem}
            >
              <span
                dangerouslySetInnerHTML={{ __html: item.html }}
              />
            </li>
          ))
        }
      </ul>
    </div>
  );
}

export default React.memo(ProductsTipsAndTricts);
